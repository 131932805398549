import React from "react"

const Card = ({ heading, paragraph, imgUrl, projectLink, date }) => {
  return (
    <div className="card">
      <div
        className="card-background-image"
        style={{
          backgroundImage:
            "linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)),url(" +
            imgUrl +
            ")",
        }}
      />
      <div className="content">
        <h1 className="header">{heading}</h1>
        <p className="text">{paragraph}</p>

        {projectLink ? (
          <a
            href={projectLink ? projectLink : "#"}
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            View on Github
          </a>
        ) : (
          <p>{date}</p>
        )}
      </div>
    </div>
  )
}

export default Card
